import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { CommonService } from '../../../app/shared/services/common.service';

import CustomStore from 'devextreme/data/custom_store';
import { Observable } from 'rxjs/internal/Observable';
import { NotificationCountRequest, NotificationCountResponse, NotificationDeleteRequest, NotificationsRequest, NotificationsResponse } from '../../shared/request-objects/notification.requests';
import { Notification } from '../../interfaces/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private commonServices: CommonService) { }

  public getNotificationCount(): Observable<number> {
    let request = new NotificationCountRequest();

    return this.commonServices.getData<NotificationCountResponse>(request, "/Notifications/NotificationCount").pipe(
      map((response: NotificationCountResponse) => response.result)
    );
  }

  private getNotifications(): Observable<Notification[]> {
    let request = new NotificationsRequest();

    return this.commonServices.getData<NotificationsResponse>(request, "/Notifications/Notifications").pipe(
      map((response: NotificationsResponse) => response.result)
    );
  }

  public deleteNotification(key: any): any {
    let request = new NotificationDeleteRequest();
    request.notificationId = key;

    return this.commonServices.deleteData(request, "/Notifications/NotificationDelete");
  }

  public NotificationStore() {
    return new CustomStore({
        key: "notificationId",
        load: () => this.getNotifications().toPromise().then(
          (result) => result, 
          (reason) => { 
            console.log('getNotifications failed',reason); 
            return [];
          }),
        // insert: (values) => this.createTransactionBatch(values),
        // update: (key, values) => this.putTransactionBatch(key, values),
        remove: (key) => this.deleteNotification(key)
    });
  }
}
