import { createSelector, createFeatureSelector } from '@ngrx/store';
import { signalRNotificationFeatureKey } from '../reducers/signalr-notification.reducers';
import { signalRNotificationAdapter, SignalRNotificationState } from '../states/signalr-notification.state';

export const {
    selectIds: _selectSignalRNotificationDataIds,
    selectEntities: _selectSignalRNotificationEntities,
    selectAll: _selectAllSignalRNotifications,
    selectTotal: _selectSignalRNotificationTotal
} = signalRNotificationAdapter.getSelectors();

export const selectSignalRNotificationState = createFeatureSelector<SignalRNotificationState>(signalRNotificationFeatureKey);

export const selectSignalRNotificationIds = createSelector(
    selectSignalRNotificationState,
    _selectSignalRNotificationDataIds
);

export const selectSignalRNotificationEntities = createSelector(
    selectSignalRNotificationState,
    _selectSignalRNotificationEntities
);

export const selectAllSignalRNotifications = createSelector(
    selectSignalRNotificationState,
    _selectAllSignalRNotifications
);

export const selectSignalRNotificationTotal = createSelector(
    selectSignalRNotificationState,
    (state: SignalRNotificationState): number => state.total
);