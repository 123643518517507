import {
    initialSignalRNotificationState,
    signalRNotificationAdapter,
} from "../states/signalr-notification.state";
import * as signalRNotification from "../actions/signalr-notification.actions";
import { createReducer, on } from "@ngrx/store";

export const signalRNotificationFeatureKey = 'signalRNotificationFeature';

export const signalRNotificationReducer = createReducer(
    initialSignalRNotificationState,
    on(signalRNotification.schoolMessageReceived, (state) => ({ ...state, loading: true })),    
   // on(signalRNotification.notificationCountLoaded, (state, { response }) => ({ ...state, loading: false, total: response.count })),
    on(signalRNotification.notificationCountLoaded, (state, { response }) => {
        console.log('signalRNotificationReducer');
        return signalRNotificationAdapter.addOne(response, {
            ...state,
            loading: false,
            total: response.count,
        })
    }
    )
    // on(signalRNotification.broadcastMessageReceived, (state, { response }) =>
    // signalrNotificationAdapter.setAll(response.customers, {
    //         ...state,
    //         error: false,
    //         loading: false,
    //         total: response.total,
    //     })
    // ),    
);