import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
//  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

  error: boolean;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
  }

  async ngOnInit() {
    // console.log('Constructor for auth-callback');
    // // check for error
    // if (this.route.snapshot.fragment !== null && this.route.snapshot.fragment.indexOf('error') >= 0) {
    //    this.error=true; 
    //    return;    
    //  }
    
    //this.authService.completeAuthentication();      
    this.router.navigate(['/home']);    
  }
}