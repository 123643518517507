import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDrawerModule } from 'devextreme-angular';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { Router } from '@angular/router';
import { AdminPanelModule } from '../admin-panel/admin-panel.component';

import { NotificationService } from 'src/app/components/notification/notification.service';
import { GlobalState } from "../../store/states/global.state";
import { Store } from "@ngrx/store";
import { selectSignalRNotificationTotal } from '../../store/selectors/signalr-notification.selectors';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;
  
  @Output()
  drawerToggle = new EventEmitter<boolean>();
  
  helpButtonOptions: any;

  notificationCount: number = 0;

  isLoggedIn: boolean = false;

  userMenuItems = [{
    text: 'Profile',
    icon: 'user',
    onClick: () => {
      this.router.navigate(['profile'])
    }
  }, {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
  }];

  adminMenuItems = [{
    text: 'Hangfire',
    icon: 'runner',
    onClick: () => {
      this.router.navigate(['hangfire'])
    }
  }];

  constructor(private authService: AuthService, private router: Router, public store: Store<GlobalState>, private notificationService: NotificationService) {
    this.helpButtonOptions = {
      type: 'normal',
      icon: 'help',
      text: 'Help',
      onClick: () => {
        window.open( "https://docs.scholasys.co.za", "_blank");
      },
    }; 
  }

  ngOnInit() {
// The authService call causes this method to be called hundres of times    
  //  this.isLoggedIn = this.authService.isLoggedIn;

  //   this.authService.isLoggedIn$.subscribe(() => {
  //     console.log('this.authService.isLoggedIn', this.authService.isLoggedIn);
  //     this.isLoggedIn = this.authService.isLoggedIn;

  //     // if (this.isLoggedIn) {
  //     //   this.showNotificationCount();
  //     // }
  //   });
    
    this.store.select(selectSignalRNotificationTotal).subscribe(result => 
      this.notificationCount = result
    )
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  // showNotificationCount() {
  //   this.notificationService.getNotificationCount().subscribe(result => this.notificationCount = result);
  // }

  showNotifications() {
    this.drawerToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    AdminPanelModule,
    DxToolbarModule,
    DxDrawerModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
