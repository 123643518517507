import { SessionContext } from './sessionContext';
import { Notification } from '../../interfaces/notification';

export class NotificationRequest {
    notificationId: number;
    sessionContext: SessionContext;
}

export class NotificationsRequest {
    sessionContext: SessionContext;
}

export class NotificationCountRequest {
    sessionContext: SessionContext;
}

export class NotificationDeleteRequest {
    notificationId: number;
    sessionContext: SessionContext;
}

export interface NotificationsResponse {
    type: string;
    result: Array<Notification>;
}

export interface NotificationResponse {
    type: string;
    result: Notification;
}

export interface NotificationCountResponse {
    type: string;
    result: number;
}