export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },      
  // {
  //   text: 'Profile',
  //   path: '/profile'
  // }, 
  {
    text: 'Students',
    icon: 'group',
    items: [
      {
        text: 'Students',
        path: '/students/studentsummary/0'
      },
      {
        text: 'Promotions',
        path: '/students/student-promotion'
      },
      {
        text: 'Parents',
        path: '/parents/parentsummary'
      },
      {
        text: 'Reports',
        icon: 'print',
        items: [
          {
            text: 'Class Lists',
            path: 'reports/classlist'
          },
          {
            text: 'Contact Information',
            path: 'reports/contactinformation'
          },
          {
            text: 'Financial Information',
            path: 'reports/financialinformation'
          }
        ]
      },
    ]
  },
  {
    text: 'Transactions',
//    path: '/transactions',
    icon: 'money',
    items: [
      {
        text: 'Transaction Batches',
        path: '/transactions/transactionbatches'
      },
      {
        text: 'Debit Orders',
        path: '/transactions/debitorderbatches'
      },
      {
        text: 'Age Analysis',
        path: '/transactions/debtorageing'
      },
      {
        text: 'Transaction Periods',
        path: '/transactions/transactionperiods'
      },
      {
        text: 'Batch Processes',
        path: '/transactions/batchprocesses'
      },
      {
        text: 'Transaction Templates',
        path: '/transactions/transactiontemplates'
      },
      {
        text: 'Reports',
        icon: 'print',
        items: [
          {
            text: 'Statements',
            path: '/transactions/statements'
          },
          {
            text: 'GL Transactions',
            path: '/transactions/gltransactions'
          },
          {
            text: 'GL Summary',
            path: '/reports/gltransactionssummary'
          }
        ]
      },
    ]
  },
  {
    text: 'Communication',
    icon: 'folder',
    items: [
      {
        text: 'Bulk SMS', 
        path: '/communication/bulksmsbatches/0'
      },
      {
        text: 'Bulk Email', 
        path: '/communication/bulkemailbatches/0'
      }
    ]
  },
  {
    text: 'Configuration',
    icon: 'preferences',
    items: [
      {
        text: 'School', path: '/configuration/school'
      },
      {
        text: 'Grades', path: '/configuration/grades'
      },
      {
        text: 'Classes', path: '/configuration/classrooms'
      },
      {
        text: 'Tax Types', path: '/configuration/taxtypes'
      },
      // {
      //   text: 'Payment Types', path: '/configuration/paymenttypes'
      // },
      {
        text: 'GL Accounts', path: '/configuration/glaccounts'
      },
      {
        text: 'Batch Types', path: '/configuration/batchtypes'
      },
      {
        text: 'Price Lists', path: '/configuration/pricelists'
      },
      {
        text: 'Student Activities', path: '/configuration/studentactivities'
      },
      {
        text: 'Email Template', path: '/configuration/emailtemplates'
      },

      // {
      //   text: 'System Configuration',
      //   items: [
      //     { 
      //       text: 'Transaction Types', path: '/configuration/transactiontypes'
      //     }
      //   ]
      // }
    ]
  }
];
