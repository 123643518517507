import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/services/auth.service';

import { GlobalState } from "../shared/store/states/global.state";
import { Store, select } from "@ngrx/store";
import {
  schoolMessageReceived
} from "../shared/store/actions/signalr-notification.actions";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public hubConnection: signalR.HubConnection;

  user: { isLoggedIn: boolean; username: string; school: string, role: string };

  constructor(private authService: AuthService, public store: Store<GlobalState>) {
    this.authService.auth$
      .subscribe(
        ({ isLoggedIn, username, school, role }) => {
          this.user = { isLoggedIn, username, school, role };
        }
      );
  }

  public initiateSignalRConnection(): Promise<void> {

    if (this.authService.isLoggedIn) {

      return new Promise((resolve, reject) => {
        this.hubConnection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(`${environment.signalRUrl}`)
          .build();

        this.hubConnection
          .start()
          .then(() => {
            console.log(`SignalR connection success! connectionId: ${this.hubConnection.connectionId} `);
            this.addBroadcastListener();   
            this.addSchoolMessageListener();
            resolve();
          })
          .catch((error) => {
            console.log(`SignalR connection to ${environment.signalRUrl} error: ${error}`);
            reject();
          });
      });
    }

    return null;
  }
  // public hubConnection: signalR.HubConnection;

  // public startConnection = () => {
  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //                           .withUrl(`${environment.signalRUrl}`)
  //                           .build();

  //   this.hubConnection
  //     .start()
  //     .then(() => console.log('Connection started'))
  //     .catch(err => console.log('Error while starting connection: ' + err))
  // }

  public addBroadcastListener = () => {
    this.hubConnection.on('Broadcast', (data) => {
      //this.data = data;
      console.log('Broadcast received: ', data);
    });
  }

  public addSchoolMessageListener = () => {
    this.hubConnection.on('SchoolMessage', (data) => {
      //this.data = data;
      console.log('SchoolMessage received for schoolId: ', data);

      // if it is my school go and update the notification count
      if (this.user.isLoggedIn && this.user.school === data) {
        this.store.dispatch(schoolMessageReceived());
      }
    });
  }
}