<app-header
    class="layout-header"
    [menuToggleEnabled]="true"
    (menuToggle)="menuOpened = !menuOpened;"
    (drawerToggle)="showNotifications();"
    [title]="title">
</app-header>

<dx-drawer
    class="layout-body"
    position="before"
    [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode"
    [revealMode]="menuRevealMode"
    [minSize]="minMenuSize"
    [shading]="shaderEnabled"
    [(opened)]="menuOpened">

    <app-side-navigation-menu
        [items]="menuItems"
        [compactMode]="!menuOpened"
        [selectedItem]="selectedRoute"
        class="dx-swatch-additional"
        *dxTemplate="let dataMenu of 'panel'"
        (selectedItemChanged)="navigationChanged($event)"
        (openMenu)="navigationClick()">
    </app-side-navigation-menu>

    <!-- <dx-drawer
        openedStateMode="overlap"
        position="right"
        revealMode="expand"
        template="template"
        [(opened)]="isDrawerOpen"

        [closeOnOutsideClick]="true"
        >
        <div *dxTemplate="let data of 'template'" style="width: 200px">

            <dx-list
                class="panel-list dx-theme-background-color"
                [dataSource]="notificationData"
                [hoverStateEnabled]="false"
                [activeStateEnabled]="false"
                [focusStateEnabled]="false"
                itemTemplate="data"
                noDataText="No notifications"
            >
                <div *dxTemplate="let item of 'data'">
                    <div *ngIf="item.status == 0">
                        <div class="card">                            
                            <div class="container">
                                <div class="container-header">
                                    <i class="dx-icon-info icon-pending"></i><strong>Pending</strong><i class="dx-icon-remove" (click)="removeNotification(item.notificationId)"></i>
                                </div>
                                <textarea rows="4" cols="25" readonly>{{ item.message }} ({{item.notificationId}})</textarea>
                            </div>
                        </div>              
                    </div>
                    <div *ngIf="item.status == 1">
                        <div class="card">                            
                            <div class="container">
                                <div class="container-header">
                                    <i class="dx-icon-info icon-success"></i><strong>&nbsp;Success</strong><i class="dx-icon-remove" (click)="removeNotification(item.notificationId)"></i>
                                </div>
                                <textarea rows="4" cols="25" readonly>{{ item.message }} ({{item.notificationId}})</textarea>
                            </div>
                        </div>              
                    </div>                                        
                    <div *ngIf="item.status == 2">
                        <div class="card">                            
                            <div class="container">
                                <div class="container-header">
                                    <i class="dx-icon-info icon-warning"></i><strong>Warning</strong><i class="dx-icon-remove" (click)="removeNotification(item.notificationId)"></i>
                                </div>
                                <textarea rows="4" cols="25" readonly>{{ item.message }} ({{item.notificationId}})</textarea>
                            </div>
                        </div>              
                    </div>                                        
                    <div *ngIf="item.status == 3">
                        <div class="card">                            
                            <div class="container">
                                <div class="container-header">
                                    <i class="dx-icon-info icon-error"></i><strong>Error</strong><i class="dx-icon-remove" (click)="removeNotification(item.notificationId)"></i>
                                </div>
                                <textarea rows="4" cols="25" readonly>{{ item.message }}</textarea>
                            </div>
                        </div>              
                    </div>                                        
                </div>
            </dx-list>
        </div> -->


    <dx-scroll-view class="full-height-scrollable">        
        <div class="content">
            <ng-content></ng-content>
        </div>
    </dx-scroll-view>
    <!-- </dx-drawer> -->
</dx-drawer>

<div class="content-block">    
    <ng-content select="app-footer"></ng-content>
</div>