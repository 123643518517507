<header>
    <dx-toolbar class="header-toolbar">
        <dxi-item
            *ngIf="menuToggleEnabled"
            location="before"
            widget="dxButton"
            cssClass="menu-button"
            [options]="{
                icon: 'menu',
                stylingMode: 'text',
                onClick: toggleMenu
            }">
        </dxi-item>
        <dxi-item
            location="before"
            cssClass="header-title"
            *ngIf="title"
            [text]="title">
        </dxi-item>
        <!-- <dxi-item
            location="before"
            cssClass="header-title"
            *ngIf="school"
            [text]="school.name">
        </dxi-item>         -->
        <dxi-item
            location="after"
            locateInMenu="auto"
            menuItemTemplate="adminMenuItemTemplate">
            <div *dxTemplate="let data of 'item'">
                <dx-button
                    class="admin-button authorization"
                    width="170px"
                    height="100%"
                    stylingMode="text">
                    <div *dxTemplate="let data of 'content'">
                        <app-admin-panel [menuItems]="adminMenuItems" menuMode="context"></app-admin-panel>
                    </div>
                </dx-button>
            </div>
        </dxi-item>

        <dxi-item
            location="after"
            locateInMenu="auto"
            menuItemTemplate="menuItemTemplate">
            <div *dxTemplate="let data of 'item'">
                <dx-button
                    class="user-button authorization"
                    width="170px"
                    height="100%"
                    stylingMode="text">
                    <div *dxTemplate="let data of 'content'">
                        <app-user-panel [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
                    </div>
                </dx-button>
            </div>
        </dxi-item>

        <!-- <dxi-item
            location="after"
            cssClass="header-title">
            <div *dxTemplate>
                <i class="fa fa-bell  has-badge" style="cursor: pointer;" (click)="showNotifications()"></i>  
                <div class="numberCircle" *ngIf="notificationCount && notificationCount>0" style="cursor: pointer;"  
                    (click)="showNotifications()">  
                    {{notificationCount}}
                </div>  
            </div>
        </dxi-item> -->

        <dxi-item *ngIf="isLoggedIn"
            location="after"
            locateInMenu="never"
            >
            <div *dxTemplate>
                <dx-button class='toolbar-button' type='default' stylingMode="text" (onClick)="showNotifications()">
                    <i class="position-relative dx-icon dx-icon-info">
                        <span class="dx-badge notify-badge">{{notificationCount}}</span>
                    </i>
                </dx-button>
            </div>
        </dxi-item>

        <dxi-item
            location="after"
            cssClass="menu-button"                
            widget="dxButton"
            [options]="helpButtonOptions">
        </dxi-item>

        <div *dxTemplate="let data of 'menuItemTemplate'">
            <app-user-panel [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
        </div>

        <div *dxTemplate="let data of 'adminMenuItemTemplate'">
            <app-admin-panel [menuItems]="adminMenuItems" menuMode="list"></app-admin-panel>
        </div>
    </dx-toolbar>
</header>
