import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of, Observable } from "rxjs";
import { NotificationService } from "../../../components/notification/notification.service";
import { schoolMessageReceived, notificationCountLoaded , loadNotificationCountFailure} from "../actions/signalr-notification.actions";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";

@Injectable()
export class SignalRNotificationEffects {
    constructor(private actions$: Actions, private service: NotificationService) { }

    public loadSignalRNotificationCount$ = createEffect(() => this.actions$.pipe(
        ofType(schoolMessageReceived),
        mergeMap(() => this.service.getNotificationCount().pipe(
          map(response => notificationCountLoaded({ response: { count: response} }),
          catchError((error: HttpErrorResponse) => of(loadNotificationCountFailure({ error })))
        ))
      )));
}