import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import { SideNavigationMenuModule, HeaderModule } from '../../shared/components';
import { AuthService, ScreenService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule, DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';

import { navigation } from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { DxButtonModule, DxListComponent, DxListModule, DxTextAreaModule } from 'devextreme-angular';
import { NotificationService } from 'src/app/components/notification/notification.service';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  menuItems = navigation;
  selectedRoute = '';

  menuOpened: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  isDrawerOpen: boolean = false;

  public notificationData: any;

  @ViewChild(DxListComponent) dataList: DxListComponent;

  constructor(private screen: ScreenService, private router: Router, 
    private notificationService: NotificationService,
    private authService: AuthService) { }

  ngOnInit() {
// there is an issue when enabling the NotificationStore and the user is not logged in. The issue seems
// to be the authService. 
// TODO Fix the notification count display


   // this.notificationData = this.notificationService.NotificationStore();
   //console.log('this.notificationData 1', this.notificationData);
  //  this.authService.isLoggedIn$.subscribe(() => {
  //   if (this.authService.isLoggedIn) {
  //     this.notificationData = this.notificationService.NotificationStore()
  //     console.log('this.notificationData 2', this.notificationData);
  //   } else {
  //     this.notificationData = undefined;
  //     console.log('this.notificationData 3', this.notificationData);
  //   }
  // });

    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  ngViewInit() {
   // this.notificationData = this.notificationService.NotificationStore();
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      // commented out the section below to allow navigating on menu item click even if the node is already selected
      // if (event.node.selected) {
      //   pointerEvent.preventDefault();
      // } else {
        this.router.navigate([path]);
        this.scrollView.instance.scrollTo(0);
      // }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }

  showNotifications() {
    this.isDrawerOpen = !this.isDrawerOpen;

    if (this.isDrawerOpen) {     
      if (this.notificationData !== undefined) {
        this.notificationData.load().then(() => this.dataList.instance.reload());
      }
    }
  }

  removeNotification(notificationId: number) {
    this.notificationData.remove(notificationId);
    if (this.notificationData !== undefined) {
      this.notificationData.load().then(() => {     
        setTimeout(() => this.dataList.instance.reload(), 250);     
      });
    }
  }
}

@NgModule({
  imports: [ SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule, DxListModule, DxTextAreaModule, DxButtonModule ],
  exports: [ SideNavOuterToolbarComponent ],
  declarations: [ SideNavOuterToolbarComponent ]
})
export class SideNavOuterToolbarModule { }
