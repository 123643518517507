import { Component, HostBinding } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import ScrollView from "devextreme/ui/scroll_view";
import packageJson from '../../package.json';
import { SignalRService } from './signalr/signalr.service';

//import { SwUpdate } from '@angular/service-worker';
//import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  public version: string = packageJson.version;
  today: number = Date.now();

  constructor(private authService: AuthService,
    private screen: ScreenService, 
    public appInfo: AppInfoService,
    public signalRService: SignalRService) { 
      
    ScrollView.defaultOptions({
      device: { deviceType: "desktop" },
      options: {
          //showScrollbar: "always"
          useNative: true
      }
    });

    // this.swUpdate.versionUpdates.subscribe(() => {
    //   // let result = confirm("A new version of ScholaSys is available. <br /> Click Ok to load it.", "New Version Available");
    //   // result.then((dialogResult) => {
    //   //   window.location.reload();
    //   // });
    // })
  }

  ngOnInit() {
    // init the signalR service if the user is already authenticated
    // this.signalRService.initiateSignalRConnection();
  }

  isAuthorized() {
    return this.authService.isLoggedIn;
  }
}