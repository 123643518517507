import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule, AuthCallbackComponent } from './shared/components';
import { HttpClientModule } from '@angular/common/http';

import { StorageModule } from './shared/store/storage.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';

export const APP_MODULE_DECLARATIONS = [
    AppComponent,
    AuthCallbackComponent
];

export const APP_MODULE_IMPORTS = [
    BrowserModule,
    HttpClientModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    AppRoutingModule,
    StorageModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
];