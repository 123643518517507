import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

import { UserAuthenticateRequest } from '../../shared/request-objects/user.requests';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import jwt_decode from 'jwt-decode';

import { LoggedInUser } from '../../interfaces/loggedin-user';
import { Role } from '../../interfaces/role';
import { map, take } from 'rxjs/operators';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import { SignalRService } from 'src/app/signalr/signalr.service';

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  id: string | null;
  login: string | null;
  email: string | null;
  school: string | null;
  token: string | null;
  expiry: string | null;
  role: Role | null;
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  id: null,
  login: null,
  email: null,
  school: null,
  token: null,
  expiry: null,
  role: null
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;

  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();

  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor(private router: Router, private http: HttpClient) {
      let user = new LoggedInUser;
      user = JSON.parse(localStorage.getItem('loggedin_user'));

      this.setUser(user);
  }

  private setUser(user: LoggedInUser) {
    if (!user) {
      return;
    }

    var decodedToken = jwt_decode(user.token); 
    let role = decodedToken['Role'];

    this.loggedIn = true;
    this._authState.next({ isLoggedIn: this.loggedIn, id: user.id, login: user.login, username: user.name, email: user.email, school: user.school, token: user.token, expiry: user.expiry, role: role });
  }

  logIn(login: string, password: string, rememberMe: boolean) : boolean {
    this.authenticateUser(login, password, rememberMe);

    return this.isLoggedIn;
  }

  logOut() {
    this.loggedIn = false;
    this._authState.next(initialAuthState);

    localStorage.removeItem("loggedin_user");
    localStorage.removeItem("expires_at");

    this.router.navigate(['/login-form']);
  }

  get isLoggedIn() {    
    if (this.loggedIn) {
      if (isBefore(new Date(), this.getExpiration())) {
        return this.loggedIn;
      }
    }

    this.logOut();
    
    return this.loggedIn;
  }

  hasRole(roles: any[]) : boolean {    
    if (this._authState.getValue().isLoggedIn) {
      return roles.indexOf(this._authState.getValue().role) !== -1;                                  
    } 

    return false;
  }

  authenticateUser(username: string, password: string, rememberMe: boolean) {
    let request = new UserAuthenticateRequest();
    request.username = username;
    request.password = password;

    this.http.post<LoggedInUser>(`${environment.apiUrl}/Users/authenticate`, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }) })
      .subscribe(
        (response) => {     
          this.setUser(response);

          if (rememberMe) {
            this.setSession(response);
          }

          console.log('set signalR');


          this.router.navigate(['/']);
        },
        (error) => {
          console.log('Login error', error);
          
          this.loggedIn = false;
          this._authState.next(initialAuthState);
        }
      );
  }

  private setSession(user: LoggedInUser) {
    const expiresAt = addMinutes(new Date(), Number(user.expiry));

    localStorage.setItem('loggedin_user', JSON.stringify(user));
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );

  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);

    return expiresAt;
  }   
}

@Injectable()
export class AuthGuardService  {
  user: { isLoggedIn: boolean; username: string; role: string };

  constructor(private router: Router, private authService: AuthService) { 
    this.authService.auth$
      .subscribe(
        ({ isLoggedIn, username, role }) => {
          this.user = { isLoggedIn, username, role };
        }
      );
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn;

    const isLoginForm = route.routeConfig.path === 'login-form';

    if (isLoggedIn && isLoginForm) {
      this.router.navigate(['/']);
      return false;
    }

    if (!isLoggedIn && !isLoginForm) {
      this.router.navigate(['/login-form']);
    }

    // check if route is restricted by role
    if (this.user && route.data && route.data.roles && route.data.roles.indexOf(this.user.role) === -1) {
      // role not authorised so redirect to home page
      this.router.navigate(['/']);
      return false;
    }

    return isLoggedIn || isLoginForm;
  }
}