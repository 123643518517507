import { Injectable } from "@angular/core";
import notify from 'devextreme/ui/notify';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { displayError, displaySuccess, displayWarning } from "../actions/notification.actions";

@Injectable()
export class NotificationEffects {
    constructor(private actions$: Actions) { 
    }

    public displaySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(displaySuccess),
                tap(action => {
                    notify({
                        message: action.description,
                        position: {
                          my: "center top",
                          at: "center top"
                        }
                      }, "success", 3000);                                       
                })
            ),
        { dispatch: false }
    );

    public displayWarning$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(displayWarning),
                tap(action => {
                    notify({
                        message: action.description,
                        position: {
                          my: "center top",
                          at: "center top"
                        }
                      }, "warning", 3000);                   
                })
            ),
        { dispatch: false }
    );

    public displayError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(displayError),
                tap(action => {
                    notify({
                        message: action.description,
                        position: {
                          my: "center top",
                          at: "center top"
                        }
                      }, "error", 3000);                   
                })
            ),
        { dispatch: false }
    );
}