import { Injectable } from '@angular/core';
import { SessionContext, ScholaSysUserConfiguration } from '../request-objects/sessionContext';
import { throwError, Observable, of, iif } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from './auth.service';
import { retryWhen, tap, delay, concatMap, retry } from 'rxjs/operators';

import { ExceptionlessClient } from 'exceptionless';

const RETRY_COUNT = 3;

const config = {
  apiKey: environment.exceptionlessAPIKey,
  serverUrl: environment.exceptionlessServerUrl,
};
const exceptionlessClient = new ExceptionlessClient(config);

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

  user: { isLoggedIn: boolean; login: string; school: string };

  constructor(private authService: AuthService, private http: HttpClient) {
    this.authService.auth$
      .subscribe(
        ({ isLoggedIn, login, school }) => {
          this.user = { isLoggedIn, login, school };
        });
  }

  getSessionContext(request: any): any {
    request.sessionContext = new SessionContext();
    request.sessionContext.ScholaSysUserConfiguration = new ScholaSysUserConfiguration();

    request.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName = this.user.login;
    request.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool = this.user.school;

    return request;
  }

  public getData<TResult>(TRequest: any, apiUrl: string, noRetry?: boolean): Observable<TResult> {
    let params: HttpParams = this.buildGetDataParams(TRequest);

    return this.http.get<TResult>(`${environment.apiUrl}${apiUrl}`, { params: params })
      .pipe(
        retry(RETRY_COUNT), // retry a failed request up to RETRY_COUNT times
        catchError(this.handleError) // then handle the error
      );
  }
  //   .pipe(    
  //     retryWhen(errors => errors.pipe(
  //       // Use concat map to keep the errors in order and make sure they
  //       // aren't executed in parallel
  //       concatMap((e, i) =>
  //         // Executes a conditional Observable depending on the result
  //         // of the first argument
  //         iif(
  //           () => i > RETRY_COUNT - 1 || noRetry,
  //           // If the condition is true we throw the error (the last error)
  //           throwError(e),
  //           // Otherwise we pipe this back into our stream and delay the retry
  //           of(e).pipe(delay(500))
  //         )
  //       ),
  //       tap(() => console.log('retrying...', `${environment.apiUrl}${apiUrl}?${params}`))
  //     )
  //   ),
  //     // retryWhen(errors => {
  //     //   return errors.pipe(
  //     //     delayWhen(() => timer(500)),
  //     //     tap(() => console.log('retrying...', errors))
  //     //   );
  //     // }),
  //   catchError(this.handleError)
  //   // err => { 
  //   //   console.log('getData(): ', err);
  //   //   //return of<TResult>() 
  //   //}
  // );

  //tap(data => console.log(JSON.stringify(data))),
  //   catchError(err => of<TResult>())
  // );
  // }

  public putData(TRequest: any, apiUrl: string): any {
    TRequest = this.getSessionContext(TRequest);
    let params: HttpParams = new HttpParams()
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysUserName', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName)
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysSchool', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool);

    return this.http.put(`${environment.apiUrl}${apiUrl}`, TRequest, { headers: this.headers, params: params })
      .toPromise()
      .catch((err) => { this.handleError(err); throw `Update failed. ${err.error?.Message}` });
  }

  public putDataForSubscription(TRequest: any, apiUrl: string): any {
    TRequest = this.getSessionContext(TRequest);
    let params: HttpParams = new HttpParams()
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysUserName', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName)
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysSchool', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool);

    return this.http.put(`${environment.apiUrl}${apiUrl}`, TRequest, { headers: this.headers, params: params }).pipe(
      catchError(this.handleError)
    );
  }

  public postData(TRequest: any, apiUrl: string): any {
    TRequest = this.getSessionContext(TRequest);
    let params: HttpParams = new HttpParams()
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysUserName', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName)
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysSchool', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool);

    return this.http.post(`${environment.apiUrl}${apiUrl}`, TRequest, { headers: this.headers, params: params })
      .toPromise()
      .catch((err) => { this.handleError(err); throw `Update failed. ${err.error?.Message}` });
  }

  public deleteData(TRequest: any, apiUrl: string): any {
    TRequest = this.getSessionContext(TRequest);

    let options = {
      headers: this.headers,
      body: TRequest,
      params: new HttpParams()
        .set('SessionContext.ScholaSysUserConfiguration.ScholaSysUserName', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName)
        .set('SessionContext.ScholaSysUserConfiguration.ScholaSysSchool', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool)
    };

    return this.http.delete(`${environment.apiUrl}${apiUrl}`, options)
      .toPromise()
      .catch((err) => { this.handleError(err); throw `Delete failed. ${err.error.Message}` });
  }

  handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;

    // console.log('common.service.handleError(): ', err);

    if (err.error !== undefined && err.error !== null) {
      exceptionlessClient.submitException(err);

      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else if (err.error! instanceof TypeError) {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Backend returned code ${err.status}: ${err.error.StatusCode} ${err.error.Message}`;
      } else {
        if (err.message !== undefined) {
          errorMessage = err.message;
        }
        errorMessage = err;
      }
    } else {
      if (err.message !== undefined) {
        errorMessage = err.message;
      }
      errorMessage = err;
    }

    return throwError(errorMessage);
  }

  private buildGetDataParams(TRequest: any): any {
    TRequest = this.getSessionContext(TRequest);

    let params: HttpParams = new HttpParams()
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysUserName', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysUserName)
      .set('SessionContext.ScholaSysUserConfiguration.ScholaSysSchool', TRequest.sessionContext.ScholaSysUserConfiguration.ScholaSysSchool);

    for (let [key, value] of Object.entries(TRequest)) {
      if (key !== 'sessionContext' && value) {
        //console.log(key + ':' + value);
        if (value instanceof Date) {
          params = params.append(key, value.toDateString());
        } else if (value instanceof Array) {
          for (let item of value) {
            params = params.append(key, item.toString());
          }
        }
        else {
          params = params.append(key, value.toString());
        }
      }
    }

    return params;
  }
}
function providedIn(providedIn: any, arg1: string) {
  throw new Error('Function not implemented.');
}

