import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthService } from '../../services/auth.service';

import { DxButtonModule } from 'devextreme-angular/ui/button';

@Component({
    selector: 'app-admin-panel',
    templateUrl: 'admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss']
})

export class AdminPanelComponent {
    @Input() menuItems: any;

    @Input() menuMode: string;

    user: { isLoggedIn: boolean; username: string; school: string, role: string };

    constructor(private authService: AuthService) {
        this.authService.auth$
            .subscribe(
                ({ isLoggedIn, username, school, role }) => {
                    this.user = { isLoggedIn, username, school, role };
                }
            );
    }
}

@NgModule({
    imports: [
        DxListModule,
        DxContextMenuModule,
        DxButtonModule,
        CommonModule
    ],
    declarations: [AdminPanelComponent],
    exports: [AdminPanelComponent]
})
export class AdminPanelModule { }