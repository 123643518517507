import { Role } from '../interfaces/role';

export class LoggedInUser   {
    public id: string;
    public login: string;
    public email: string;
    public name: string;
    public school: string;
    public token: string;
    public expiry: string;
    public role: Role;
}