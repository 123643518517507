import { createAction, props } from "@ngrx/store";

enum NotificationActionType {
    Success = "[UI Notification] Display Success",
    Warning = "[UI Notification] Display Warning",
    Error = "[UI Notification] Display Error",
}
export const displaySuccess = createAction(
    NotificationActionType.Success,
    props<{ title: string; description: string }>()
);
export const displayWarning = createAction(
    NotificationActionType.Warning,
    props<{ title: string; description: string }>()
);
export const displayError = createAction(
    NotificationActionType.Error,
    props<{ title: string; description: string }>()
);