import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Role } from './interfaces/role';
import { LoginFormComponent, AuthCallbackComponent } from './shared/components';
import { AuthGuardService, AuthService } from './shared/services';

const routes: Routes = [
  {
    path: 'login-form',
    component: LoginFormComponent
  },
  {
    path: 'auth-callback',
    component:AuthCallbackComponent
  },
  {
    path: 'students',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/students/student.module').then(m => m.StudentModule)
  },
  {
    path: 'parents',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/parents/parent.module').then(m => m.ParentModule)
  },
  {
    path: 'transactions',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/transactions/transaction.module').then(m => m.TransactionModule)
  },
  {
    path: 'configuration',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/references/references.module').then(m => m.ReferencesModule)
  },
  {
    path: 'communication',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/communication/communication.module').then(m => m.CommunicationModule)
  },  
  {
    path: 'reports',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./components/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'hangfire',
    canActivate: [ AuthGuardService ],
    data: { roles: [Role.SuperAdmin] },
    loadChildren: () =>
      import('./components/hangfire/hangfire-container.module').then(m => m.HangfireContainerModule)
  },
  {
    path: '',
    canActivate: [ AuthGuardService ],
    data: { preload: false },
    loadChildren: () =>
      import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: '',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [AuthGuardService, AuthService ],
  exports: [RouterModule],
  declarations: [ ]
})
export class AppRoutingModule { }
