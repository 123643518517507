import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { APP_MODULE_DECLARATIONS, APP_MODULE_IMPORTS } from './app.module.dependencies';

import { ScreenService, AppInfoService, AuthService } from './shared/services';

import { CommonService } from './shared/services/common.service';

import { httpInterceptorProviders } from './shared/http-interceptors';
import { NotificationComponent } from './components/notification/notification.component';

//import { SignalRService } from './signalr/signalr.service';

@NgModule({
    declarations: [
      ...APP_MODULE_DECLARATIONS,
      NotificationComponent
  ],
  imports: [
    ...APP_MODULE_IMPORTS
  ],
  providers: [ 
    ScreenService, 
    AppInfoService, 
    CommonService, 
    httpInterceptorProviders, 
    AuthService,
    // SignalRService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (signalrService: SignalRService) => () => signalrService.initiateSignalrConnection(),
    //   deps: [SignalRService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { 
  constructor(private _auth: AuthService, _cs: CommonService) {}
}
