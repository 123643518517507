import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import notify from 'devextreme/ui/notify';

import { AuthService, AppInfoService } from '../../services';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxTemplateModule } from 'devextreme-angular';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  login = '';
  password = '';
  rememberMe : boolean = true;
  loadIndicatorVisible = false;
  loginText = 'Login';

  constructor(private authService: AuthService, public appInfo: AppInfoService) { }

  onLoginClick(args) {
    this.loadIndicatorVisible = true;

    this.authService.logIn(this.login, this.password, this.rememberMe);
    setTimeout (() => {
      if (!this.authService.isLoggedIn) {
        notify({
          message: "Login failed",
          position: {
            my: "center top",
            at: "center top"
          }
        }, "error", 3000);
      }

      this.loadIndicatorVisible = false;
    }, 2000);

    args.preventDefault();
  }
}
@NgModule({
  imports: [
    CommonModule,
    // RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxLoadIndicatorModule,
    DxTemplateModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
