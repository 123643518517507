import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { reducers } from '.';

import { NotificationEffects } from './effects/notification.effects';
import { signalRNotificationFeatureKey, signalRNotificationReducer } from './reducers/signalr-notification.reducers';
import { SignalRNotificationEffects } from './effects/signalr-notification.effects';

@NgModule({
    imports: [
        StoreModule.forRoot(reducers),
        StoreModule.forFeature(signalRNotificationFeatureKey, signalRNotificationReducer),
      //  StoreModule.forFeature(stationFeatureKey, stationReducer),
        EffectsModule.forRoot([ 
          NotificationEffects,
          SignalRNotificationEffects

      ]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    exports: [StoreModule]
})
export class StorageModule { }