import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SignalRNotification } from '../models/signalr-notification.model';

export interface SignalRNotificationState extends EntityState<SignalRNotification> {
    loading: boolean;
    total: number;
}

export const signalRNotificationAdapter: EntityAdapter<SignalRNotification> = createEntityAdapter<SignalRNotification>({
    selectId: (notification: SignalRNotification) => notification.count
});

export const initialSignalRNotificationState: SignalRNotificationState = signalRNotificationAdapter.getInitialState({
    loading: false,
    total: 0
});