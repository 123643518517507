import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  @Input() menuItems: any;

  @Input() menuMode: string;

  user: { isLoggedIn: boolean; username: string; school: string };

  constructor(private authService: AuthService, private router: Router) {
    this.authService.auth$
      .subscribe(
        ({ isLoggedIn, username, school }) => {
          this.user = { isLoggedIn, username, school };
        }
      );
  }

  onLoginClick() {
    this.router.navigate(['/login-form']);
  }

}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    DxButtonModule,
    CommonModule
  ],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent]
})
export class UserPanelModule { }