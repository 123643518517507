<ng-container> <!-- *ngIf="isAutorized(); else notAuthorized"> -->
    <app-side-nav-outer-toolbar title="{{appInfo.title}}">
        <router-outlet></router-outlet>
        <app-footer>
            Copyright © {{ today | date:'YYYY'}} C.A & W Services CC | <a href="./assets/Terms and Conditions of Use.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> | Support: <a mailto="support@scholasys.co.za">support@scholasys.co.za</a> | Version: {{ version }}
        </app-footer>
    </app-side-nav-outer-toolbar>
</ng-container>

<!-- <ng-template #notAuthorized>
    <app-single-card>
        <router-outlet></router-outlet>
    </app-single-card>
</ng-template>  -->
