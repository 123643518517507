<div class="content-block dx-card responsive-paddings">
    <form class="login-form" (submit)="onLoginClick($event)">
    <dx-validation-group>
        <div class="login-header">
            <div class="title">{{appInfo.title}}</div>
            <div>Sign In to your account</div>
        </div>
        <div class="dx-field">
            <dx-text-box [(value)]="login" placeholder="Login" width="100%">
                <dx-validator>
                    <dxi-validation-rule type="required" message="Login is required"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="dx-field">
            <dx-text-box mode="password" [(value)]="password" placeholder="Password" width="100%">
                <dx-validator>
                    <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <!-- <div class="dx-field">
            <dx-check-box [(value)]="rememberMe" text="Remember me"></dx-check-box>
        </div> -->
        <div class="dx-field">
            <dx-button id="button" type="default" [useSubmitBehavior]="true">
                <dx-load-indicator
                    class="button-indicator"
                    [visible]="loadIndicatorVisible"
                    >
                </dx-load-indicator>
              <span class="dx-button-text">{{ loginText }}</span>
            </dx-button>
        </div>
        <!-- <div class="dx-field">
            <a routerLink="/recovery">Forgot password ?</a>
        </div> -->
        <!-- <div class="dx-field">
            <dx-button type="normal" text="Create an account" width="100%"></dx-button>
        </div> -->
    </dx-validation-group>
    </form>
</div>