<div class="user-panel" *ngIf="user.isLoggedIn; else loggedOut">
    <div class="user-info">
        <div class="image-container">
            <div class="user-image"></div>
        </div>
        <div class="user-name">{{ user.username }}</div>
    </div>
    <dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        target=".user-button"
        showEvent="dxclick"
        width="170px"
        [position]="{ my: { x: 'center', y: 'top'}, at: { x: 'center', y: 'bottom' } }"
        cssClass="user-menu">
    </dx-context-menu>
    <dx-list
        *ngIf="menuMode === 'list'"
        class="dx-toolbar-menu-action"
        [items]="menuItems">
    </dx-list>
</div>
<ng-template #loggedOut>
    <!-- <dx-button type="default" text="Login" (onClick)="onLoginClick()"></dx-button> -->
    <div class="user-info">      
        <div class="image-container">
            <div class="user-image"></div>
        </div>
        <div class="user-name">
            Not logged in
        </div>
    </div>    
</ng-template>

