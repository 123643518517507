import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  user: { isLoggedIn: boolean; username: string; school: string; token: string };

  constructor(private authService: AuthService) {
    this.authService.auth$
      //   .pipe(tap(val => console.log(val)))
      .subscribe(
        ({ isLoggedIn, username, school, token }) => {
          this.user = { isLoggedIn, username, school, token };
        }
      );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.user.isLoggedIn) {
      let tokenValue = 'Bearer ' + this.user.token;
      requestToForward = req.clone({ setHeaders: { Authorization: tokenValue, 'Client': 'ScholaSys-Angular' } });
      //  console.log('Token', this.user.token);
    } // else {
    //   console.debug('User not logged in. No auth header');
    // }

    return next.handle(requestToForward).pipe(catchError(      
      err => {        
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this.authService.logOut();
          location.reload();
        }

        if (err.status === 0) {
                notify({
                  message: "The server is not currently available", //. Reason: " + err.statusText,
                  position: {
                  my: "center top",
                  at: "center top"
                }
            }, "error", 3000);
        }

        return throwError(err);
      }
    ))
  } 
}
