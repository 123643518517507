import { createAction, props } from "@ngrx/store";
import { SignalRNotification } from "../models/signalr-notification.model";

enum SignalRNotificationActionType {
    SchoolMessageReceived = "[SignalR Notification] School Message Received",
    NotificationCountLoaded = "[SignalR Notification] Notification Count Loaded",
    NotificationCountError = "[SignalR Notification] Notification Count Error",
    BroadcastMessageReceived = "[SignalR Notification] Broadcast Message Received",
}
export const schoolMessageReceived = createAction(
    SignalRNotificationActionType.SchoolMessageReceived
);
export const notificationCountLoaded = createAction(
    SignalRNotificationActionType.NotificationCountLoaded,
    props<{ response: SignalRNotification }>()
);
export const broadcastMessageReceived = createAction(
    SignalRNotificationActionType.BroadcastMessageReceived,
    props<{ title: string; description: string }>()
);

export const loadNotificationCountFailure = createAction(
    SignalRNotificationActionType.NotificationCountError,
    props<{ error: any }>()
);